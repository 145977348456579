.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 20px;
    width: 100%;
    transition: transform 0.5s ease-in-out; /* Transition for smooth sliding with ease-in-out effect */
    z-index: 1000;
  }
  
  .navbar.scrolled {
    transform: translateY(-105%); /* Slide the blue section up */
    background: transparent; /* Change to your desired color */
  }
  
  .logo {
    /* Adjust width and height as needed */
    width: 220px;
    height: 100px; /* Automatically adjust height to maintain aspect ratio */
  }
  
  @media (max-width: 991px) {
    .logo {
      height: 50px; /* Automatically adjust height to maintain aspect ratio */
    }
  }

  .logo img {
    max-width: 100%;
    height: auto;
  }
  
  
  .nav-links {
    display: flex;
    justify-content: flex-end;
    list-style: none;
    margin-right: 20px;
  }
  
  .nav-links li {
    margin-left: 20px;
  }

  /* Styles for desktop */
.desktop-logo {
  display: block;
}

img.mobile-logo {
  display: none;
}


.logout-btn {
  display: flex;
  align-items: center; /* Center icon and text vertically */
  cursor: pointer;
  font-size: x-large;
  margin-right: 50px;
  text-decoration: none; /* Remove underline */
  color: white; /* Adjust as needed */
}

.logout-btn:hover {
  color: #007bff; /* Adjust hover color as needed */
}

.logout-btn-mobile {
  display: none;
}

  
  /* Media query for mobile view */
  @media (max-width: 991px) {
    .desktop-logo {
      display: none;
    }

    
    .mobile-logo {
      display: block;
    }

     img.mobile-logo {
      display: block;
      height: 80px;
      width: auto;
      position: absolute;
      top: 20px;
      left:20px;
      padding: 10px;
      filter: brightness(200%); /* Adjust the percentage as needed */
    }
  }



  
  /* Media query for mobile view */
  @media (max-width: 767px) {

    .logout-btn {
      display: none;
    }
    
    .logout-btn-mobile {
      display: block;
      text-align: right;
      cursor: pointer;
      font-size: large;
      margin-right: 30px;
      text-decoration: none; /* Remove underline */
      color: white; /* Adjust as needed */
    }

  }


    
  /* Media query for mobile view */
  @media (max-width: 1200px) {

     .navbar {
      top: 40px;
    }
  }


